import { type CallAPIResult, callOneTextAPI } from './api';

import { HTTP_METHOD, type PostAssociateAPI, type PostCustomerPropertiesAPI } from '@onetext/api';

export const associate = (
    body : PostAssociateAPI['request']
) : Promise<CallAPIResult<PostAssociateAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'associate',
        body
    });
};

export const addCustomerProperties = (
    body : PostCustomerPropertiesAPI['request']
) : Promise<CallAPIResult<PostCustomerPropertiesAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'customer/properties/set',
        body
    });
};
