import type { CallAPIResult } from '../api';

import { getSDKAccountToken, getSDKClientID, isDevice, promiseTry } from '../../lib';
import { callOneTextAPI, constructOneTextURL, redirectInPopup } from '../api';

import { type CORE_FLOW_TYPE, HTTP_METHOD, type PostCoreFlowLinkAPI, type PostFlowCoreStartAPI } from '@onetext/api';

export const startCoreFlow = (
    body : PostFlowCoreStartAPI['request']
) : Promise<CallAPIResult<PostFlowCoreStartAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'flow/core/start',
        body
    });
};

export const getCoreFlowLink = (
    body : PostCoreFlowLinkAPI['request']
) : Promise<CallAPIResult<PostCoreFlowLinkAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'flow/core/link',
        body
    });
};

type GetRawCoreFlowLinkOptions = {
    message ?: string,
    email ?: string,
    coreFlowType : string,
    customFlowParameters ?: Record<string, unknown>,
    forceSMSRedirect ?: boolean,
};

export const getRawCoreFlowLink = ({
    coreFlowType,
    message,
    customFlowParameters,
    forceSMSRedirect = false
} : GetRawCoreFlowLinkOptions) : string => {
    const accountToken = getSDKAccountToken();
    const clientID = getSDKClientID();

    if (!accountToken && !clientID) {
        throw new Error(`Can not determine clientID`);
    }

    const query : Record<string, string> = {
        coreFlowType,
        domain: window.location.origin
    };

    if (customFlowParameters) {
        query.parameters = JSON.stringify(customFlowParameters);
    }

    if (message) {
        query.message = message;
    }

    if (accountToken) {
        query.accountToken = accountToken;
    }

    if (clientID) {
        query.clientID = clientID;
    }

    if (forceSMSRedirect) {
        query.forceSMSRedirect = 'true';
    }

    return constructOneTextURL({
        path: 'flow/start',
        query
    });
};

type GenerateCoreFlowLinkOptions = {
    message ?: string,
    email ?: string,
    coreFlowType : CORE_FLOW_TYPE.WELCOME,
    customFlowParameters ?: JSONObject,
    forceSMSRedirect ?: boolean,
    raw ?: boolean,
};

export const generateCoreFlowLink = ({
    message,
    email,
    coreFlowType,
    customFlowParameters,
    forceSMSRedirect,
    raw
} : GenerateCoreFlowLinkOptions) : Promise<string> => {
    return promiseTry(() => {
        return raw
            ? getRawCoreFlowLink({
                message,
                email,
                coreFlowType,
                customFlowParameters,
                forceSMSRedirect
            })
            : getCoreFlowLink({
                message,
                channel: 'sms',
                coreFlowType,
                customFlowParameters,
                forceSMSRedirect
            }).then(({
                body
            }) => {
                return body.link;
            });
    });
};

type SendCoreFlowStartMessageOptions = {
    message ?: string,
    email ?: string,
    coreFlowType : string,
    customFlowParameters ?: Record<string, unknown>,
    forceSMSRedirect ?: boolean,
};

export const sendCoreFlowStartMessage = ({
    coreFlowType,
    message,
    customFlowParameters,
    forceSMSRedirect = false
} : SendCoreFlowStartMessageOptions) : Promise<void> => {
    return promiseTry(() => {
        if (isDevice()) {
            return redirectInPopup({
                url: getRawCoreFlowLink({
                    coreFlowType,
                    message,
                    customFlowParameters,
                    forceSMSRedirect
                })
            });
        }
    });
};
