import { type CallAPIResult, callOneTextAPI } from './api';

import { HTTP_METHOD, type PostOptInAPI } from '@onetext/api';

export const submitOptIn = (
    body : PostOptInAPI['request']
) : Promise<CallAPIResult<PostOptInAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'optin',
        body
    });
};
