/* @flow */

import { type CallAPIResult, callOneTextAPI } from './api';

import { HTTP_METHOD, type PostShopifyBrowseAPI } from '@onetext/api';

export const sendShopifyBrowseWebhook = (
    body : PostShopifyBrowseAPI['request']
) : Promise<CallAPIResult<PostShopifyBrowseAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'shopify/webhook/client/browse',
        body
    });
};
