import { initializeCustomer } from './biz';
import { initializeIntegrations } from './integrations';
import { hotReloadPageOnSDKUpdate, isBot, isDevelopment, logger, promiseTry, trackError } from './lib';

export * from './index';

const triggerOneTextLoad = () : void => {
    void logger.info('onetext_sdk_load').flush();

    const existingOneTextLoad = window.onLoadOneText ??= [];

    const load = (...loaders : ReadonlyArray<OneTextLoader>) : number => {
        loaders.forEach(loader => {
            setTimeout(() => {
                if (typeof loader === 'function') {
                    void logger.info('onetext_child_sdk_load').flush();

                    void loader();
                } else {
                    logger.addPayloadBuilder(() => {
                        return {
                            childSDKName:       loader.name,
                            childSDKCommitHash: loader.commitHash
                        };
                    });

                    void logger.info('onetext_child_sdk_load').flush();

                    if (
                        sdk.commitHash &&
                        loader.commitHash &&
                        loader.commitHash !== sdk.commitHash
                    ) {
                        const err = new Error(`Child SDK commit hash mismatch. Expected ${ sdk.commitHash }, got ${ loader.commitHash }`);

                        trackError(err, {
                            type:      'child_sdk_commit_hash_mismatch',
                            hardError: false
                        });
                    }

                    void loader.loader();
                }
            }, 0);
        });

        return loaders.length;
    };

    existingOneTextLoad.push = load;

    load(...existingOneTextLoad);
};

void promiseTry(async () => {
    await promiseTry(() => {
        if (isBot()) {
            void logger.info('sdk_init_abort_bot').flush();
            return;
        }

        return Promise.all([
            triggerOneTextLoad(),
            initializeIntegrations(),
            initializeCustomer(),
            isDevelopment()
                ? hotReloadPageOnSDKUpdate()
                : undefined
        ]);
    }).catch(err => {
        trackError(err, {
            type:      'sdk_init',
            hardError: false
        });
    });
});
