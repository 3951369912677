import type { HTTPHeaders } from '../types';

import { APIResponseError, delay, getAPIDomain, getSDKAuthHeader, promiseTry, UnhandledError } from '../lib';

import { HTTP_CONTENT_TYPE, HTTP_HEADER, HTTP_METHOD, type Milliseconds } from '@onetext/api';

type Fetch = typeof globalThis.fetch;

let apiFetch : Fetch | undefined;

const getFetch = () : Fetch => {
    if (apiFetch) {
        return fetch;
    }

    let originalFetch : Fetch | undefined;

    try {
        const shadowRoot = document.createElement('div').attachShadow({ mode: 'open' });
        originalFetch = shadowRoot.ownerDocument.defaultView?.fetch;
    } catch {
        // pass
    }

    if (originalFetch && originalFetch !== window.fetch) {
        apiFetch = originalFetch;
        return apiFetch;
    }

    apiFetch = window.fetch;
    return apiFetch;
};

type CallAPIOptions = {
    method : HTTP_METHOD,
    url : string,
    body ?: unknown,
    headers ?: HTTPHeaders,
    allowedRetriesOnNetworkError ?: number,
    allowedRetriesOnServerError ?: number,
};

export type CallAPIResult<ResponseBodyType> = {
    status : number,
    body : ResponseBodyType,
};

export const callAPI = <ResponseBodyType>(
    opts : CallAPIOptions
) : Promise<CallAPIResult<ResponseBodyType>> => {
    const {
        method = HTTP_METHOD.POST,
        url,
        body,
        headers,
        allowedRetriesOnNetworkError = 3,
        // Pass idempotency id before increasing
        allowedRetriesOnServerError = 0
    } = opts;

    const finalHeaders : HTTPHeaders = {
        ...headers,
        [ HTTP_HEADER.CONTENT_TYPE ]: HTTP_CONTENT_TYPE.JSON,
        [ HTTP_HEADER.ACCEPT ]:       HTTP_CONTENT_TYPE.JSON
    };

    const fetch = getFetch();

    return fetch(url, {
        method,
        headers: finalHeaders,
        body:    JSON.stringify(body)
    }).then(res => {
        const contextID = res.headers.get(HTTP_HEADER.ONETEXT_CONTEXT_ID);
        const responseStatus = res.status;
        const isServerError = responseStatus.toString().charAt(0) === '5';

        const responseHeaders : HTTPHeaders = {};

        for (const [ key, value ] of res.headers.entries()) {
            if (key && value) {
                responseHeaders[key.toLowerCase()] = value;
            }
        }

        const allowRetry = responseHeaders[HTTP_HEADER.ALLOW_RETRY] !== 'false';

        if (
            isServerError &&
            allowRetry &&
            allowedRetriesOnServerError > 0
        ) {
            return delay(1000 as Milliseconds).then(() => {
                return callAPI({
                    ...opts,
                    allowedRetriesOnServerError: allowedRetriesOnServerError - 1
                });
            });
        }

        if (!res.ok) {
            throw new APIResponseError({
                message: `SDK API call to ${ method } ${ url } failed with status ${ res.status }${ contextID
                    ? ` (context id: ${ contextID })`
                    : '' }`,
                status:    responseStatus,
                headers:   responseHeaders,
                body:      undefined,
                hardError: isServerError
            });
        }

        return res.json().then(json => {
            return {
                status: responseStatus,
                body:   json as ResponseBodyType
            };
        }, err => {
            throw new APIResponseError({
                message: `SDK API call to ${ method } ${ url } response read failed with error ${ contextID
                    ? `(context id: ${ contextID })`
                    : '' }`,
                originalError: err,
                status:        responseStatus,
                headers:       responseHeaders,
                body:          undefined,
                hardError:     true
            });
        });
    }, err => {
        if (allowedRetriesOnNetworkError > 0) {
            return delay(1000 as Milliseconds).then(() => {
                return callAPI({
                    ...opts,
                    allowedRetriesOnNetworkError: allowedRetriesOnNetworkError - 1
                });
            });
        }

        throw new UnhandledError({
            message:       `SDK API call to ${ method } ${ url } failed`,
            originalError: err
        });
    });
};

type CallOneTextAPIOptions = {
    method : HTTP_METHOD,
    path : string,
    body ?: unknown,
};

export const callOneTextAPI = <ResponseBodyType>({
    method,
    path,
    body
} : CallOneTextAPIOptions) : Promise<CallAPIResult<ResponseBodyType>> => {
    const url = `${ getAPIDomain() }/api/${ path }`;
    const headers : HTTPHeaders = {};

    const authHeader = getSDKAuthHeader();

    if (authHeader) {
        headers[HTTP_HEADER.AUTHORIZATION] = authHeader;
    }

    return callAPI({
        method,
        url,
        headers,
        body
    });
};

type ConstructOneTextURLOptions = {
    path : string,
    query ?: Record<string, string>,
};

export const constructOneTextURL = ({
    path,
    query
} : ConstructOneTextURLOptions) : string => {
    const queryString = new URLSearchParams(query).toString();
    return `${ getAPIDomain() }/${ path }?${ queryString }`;
};

type RedirectInPopupOptions = {
    url : string,
};

export const redirectInPopup = ({
    url
} : RedirectInPopupOptions) : Promise<void> => {
    return promiseTry(() => {
        window.open(url, '_blank');
    });
};
