import { type CallAPIResult, callOneTextAPI } from './api';

import { HTTP_METHOD, type PostShopifyViewProductAPI } from '@onetext/api';

export const sendShopifyProductViewWebhook = (
    body : PostShopifyViewProductAPI['request']
) : Promise<CallAPIResult<PostShopifyViewProductAPI['response']>> => {
    return callOneTextAPI({
        method: HTTP_METHOD.POST,
        path:   'shopify/webhook/client/product/view',
        body
    });
};
